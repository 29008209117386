<div  class="open-link" *ngIf="checkruninngBanner()" >
    <marquee><span class="mr75"><a class="ethics-link"  href="https://www.accenture.com/in-en/about/company/business-ethics" target="_blank">There are new changes in Accenture's Supplier Standard of Conduct. Click here to learn more.</a></span> </marquee>
  </div>
<div class="search-header" *ngIf="searchIf">
    Let's find a supplier!
</div>
<div class="search-header-title" *ngIf="searchIf">
    Supplier Duplicate Check: Find existing supplier first!<img class="modal-icon" src="assets/icons/info-blue.svg"
        style="margin-top: -3px;margin-left: 5px;transform:rotate(180deg);" alt="icon-info" />
</div>
<div (click)="backPage()" *ngIf="!searchIf"
    style="width: 60px;line-height:50px;font-size: 14px;font-weight:bold;font-family: Graphik;cursor: pointer;margin-left: 5rem;color: #4e4e87;">
    < Back </div>
        <div>
            <div class="search-content">
                <div class="search-container mx-4 ">
                    <div class="search-textbox d-flex rounded-pill bg-white ps-3 pe-1 py-1 search-error-textbox search-button"
                        [ngClass]="{ 'search-error-textbox': errorState }"> 
                <input type="text" id="searchInput" class="pb-1 flex-grow-1" size="30"
                        placeholder="Search by company name" [(ngModel)]="searchCriteria.supplierName"
                        (click)="clearError()" (keydown)="searchKeyDown($event)" aria-label="input-search"
                        (input)="checkSupplierNameLength($event)" maxlength="5000"/>   
            <div *ngIf="checkSupplierName" id="supplierNameErrMsg" 
                        role="alert"
                style="color: red;margin-top: 45px;
        white-space: nowrap;position: absolute; font-family:Graphik-Medium, Arial, Helvetica, sans-serif; font-size: 14px; overflow: auto;">Please enter more than
                     3 letters.</div>   
    <div class="sh-btn flex-row-reverse">
        <sh-button [props]="advSearchSearchButton"> </sh-button>
    </div>
</div>
                </div>
                <div class="advanced-search-button advanced-search-supplier search-adv  align-items-center rounded-pill px-3 pt-1 pb-2 search-button"
                    style="margin-top: 10px;">
                    <strong class="advanced-search-btn search-btn cursor-pointer" style="color:rgb(78, 78, 135)"
                        aria-label = "click on advance search dropdown button to view the advance search dropdown content"
                        (click)="toggleAdvancedSearch()" tabindex="0" (keydown.enter)="toggleAdvancedSearch()">
                        Advanced search
                        <svg width="1em" height="1em" viewBox="4 4 16 16" class="bi bi-chevron-down"
                            [ngClass]="{ rotate: isAdvanceSearchOpen }" fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg"
                            aria-label = "click on advance search dropdown button to view the advance search dropdown content">
                            <path fill-rule="evenodd" d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
                        </svg>
                    </strong>
                </div>
            </div>
        </div>

        <div class="advanced-search advanced-search-content" *ngIf="isAdvanceSearchOpen"
            style="width:62%;border-radius: 16px;margin-top: 10px;">
            <div class="search-panel bg-white shadow-sm mb-3 mx-0">
                <div class="filters-area row g-3 pb-3">
                    <div class="col-4">
                        <label for="sapId" class="form-label" [ngClass]="checkSapId ? 'red' : 'black'">SAP ID</label>
                        <input type="text" class="form-control supplierName sapId" [(ngModel)]="searchCriteria.sapId"
                            placeholder="SAP ID" (input)="check()" />
                        <div *ngIf="checkSapId" id="sapIdErrMsg" style="color: red;">Please enter a 9 digits number.
                        </div>
                    </div>
                    <div class="col-4">
                        <label for="taxId" class="form-label taxId" [ngClass]="checkTaxId ? 'red' : 'black'">Business
                            Registration Number
                            <img class="info-icon-image" src="assets/icons/info-blue.svg" alt="icon-info">
                            <span class="info-icon">
                                <span class="tooltiptext">This field can be used to search based on BRN number, VAT ID
                                    and other Tax ID types.<br>
                                    Social Security Number (SSN) cannot be used to search suppliers.</span>
                            </span>
                        </label>
                        <input type="text" id="taxId" class="form-control supplierName taxId"
                            [(ngModel)]="searchCriteria.taxId" placeholder="TAX ID" (input)="check()" />
                        <div *ngIf="checkTaxId" id="taxIdErrMsg" style="color: red;">Please enter more than 3
                            digits/letters.</div>
                    </div>
                    <div class="col-4">
                        <label for="supplierName" class="form-label" id="dunsNumber"
                            [ngClass]="checkNumber ? 'red' : 'black'">D-U-N-S Number</label>
                        <input type="text" class="form-control supplierName" [(ngModel)]="searchCriteria.duns"
                            placeholder="D-U-N-S Number" (input)="check()" />
                        <div *ngIf="checkNumber" id="dunsNumberErrMsg" style="color: red;">Please enter a 9 digits
                            number.</div>
                    </div>
                    <div class="col-4">
                        <label for="countryServedTwo" class="form-label">Country Served</label>
                        <sh-multiple-dropdown [dataSource]="activeCountryData"
                            [selectedKeys]="searchCriteria.countryServedTwo" [placeholder]="'Country Served'"
                            [placeholderClass]="'input-placeholder'"
                            (saveSectionEvent)="saveSectionEvent($event, 'countryServedTwo')"
                            id="countryServedTwo"></sh-multiple-dropdown>
                    </div>
                    <div class="col-4 ">
                        <label for="companyCode" class="form-label">Company Code</label>
                        <div class="error" *ngIf="isDisabled">Gets enabled based on Country Served</div>
                        <sh-multiple-dropdown [readonly]="true" [disabled]="isDisabled"
                            [dataSource]="tempcompanyCodeData" [selectedKeys]="searchCriteria.companyCode"
                            [placeholder]="'Company Code'" [placeholderClass]="'input-placeholder'"
                            (saveSectionEvent)="saveSectionEvent($event, 'companyCode')" id="companyCode"
                            [ngClass]="{ isDisable : (isDisabled === true) ? true : false}">
                        </sh-multiple-dropdown>
                    </div>
                    <div class="col-4">
                        <label for="supplierName" class="form-label" id="internationalCompanyName"
                            [ngClass]="checkInternational ? 'red' : 'black'">International Company Name</label>
                        <input type="text" class="form-control supplierName" [(ngModel)]="searchCriteria.International"
                            placeholder="International Company Name" (input)="checkcompanyName()" />
                        <div *ngIf="checkInternational" id="internationalErrMsg" style="color: red;">The text entered
                            exceeds the maximum length of 255.</div>
                    </div>
                    <div class="col-4">
                        <label for="countryLocationTwo" class="form-label">Company Location</label>
                        <sh-multiple-dropdown [dataSource]="tempLocation"
                            [selectedKeys]="searchCriteria.countryLocationTwo" [placeholder]="'Company Location'"
                            [placeholderClass]="'input-placeholder'"
                            (saveSectionEvent)="saveSectionEvent($event, 'countryLocationTwo')"
                            id="countryLocationTwo"></sh-multiple-dropdown>
                    </div>
                    <div class="col-4">
                        <label for="saStatus" class="form-label">Sustainability Assessment Status</label>
                        <sh-multiple-dropdown [dataSource]="tempAssessmentStatus"
                            [selectedKeys]="searchCriteria.saStatus" [placeholder]="'Sustainability Assessment Status'"
                            [placeholderClass]="'input-placeholder'"
                            (saveSectionEvent)="saveSectionEvent($event, 'saStatus')"
                            id="saStatus"></sh-multiple-dropdown>
                    </div>
                    <div class="col-4">
                        <label for="statusTwo" class="form-label">Profile Status</label>
                        <sh-multiple-dropdown [dataSource]="profileStatus" [selectedKeys]="searchCriteria.statusTwo"
                            [placeholder]="'Profile Status'" [placeholderClass]="'input-placeholder'"
                            (saveSectionEvent)="saveSectionEvent($event, 'statusTwo')"
                            id="statusTwo"></sh-multiple-dropdown>
                    </div>
                </div>
                <div class="d-flex flex-row-reverse">
                    <sh-button style="margin-bottom: 10px" [props]="advSearchSearchButton"></sh-button>
                    <sh-button class="cancel-btn" style="margin-bottom: 10px; margin-right: 30px"
                        [props]="advSearchCancelButton"></sh-button>
                </div>
            </div>

        </div>
        <div *ngIf="searchIf">
            <div
                style="margin-top: 6%;text-align:center;color: rgb(78, 78, 135);font-size: 14px; font-family: Graphik;font-weight: bold;">
                <span (click)="openPossibleScenariosPopUp()" style="cursor: pointer" tabindex="0" (keydown.enter)="openPossibleScenariosPopUp()">
                    <img src="assets/icons/icon-help-enabled.svg" alt="">
                    Check out the possible scenarios or functions within True Supplier Marketplace →
                </span>
            </div>
        </div>
        <div class="loadEffect" style="position: fixed;left: 50%;top:30%;z-index: 1000;" *ngIf="loading">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>