import _ from 'lodash';

import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { ActivatedRoute } from "@angular/router";
import {
    ButtonPropsType,
    ComponentConfig,
    DynamicContext,
    DynamicFormComponent,
    AlertPropsType,
    SupplierRelationshipService,
    SingleChoiceDropdownComponent
} from "@lsl16/sustainability-shared-components";
import { SupplierService } from "../../services/supplier/supplier.service";
import { SupplierGeneralProfileService } from "src/app/services/supplier-general-profile.service";
import { Subscription } from "rxjs";
import { BlacklistService } from "../../services/blacklist/blacklist.service";
import { EsgQuestionnaireService, MetadataService } from "@lsl16/sushub-frontend";
import { AuthService } from "src/app/services/auth/auth.service";
import { responseEnum } from "../../../utilities/response-enum";
import { AdminBoardService } from 'src/app/services/adminboard/admin-board.service';
import { Context } from 'src/app/questionnaire/constants';
import { countryList } from 'src/utilities/const/country-list';
import { environment } from "../../../environments/environment";
import { HttpService } from "@lsl16/sustainability-shared-components";

const SUPPLIER_POC_COMPONENT = "supplierPoC";

@Component({
    selector: 'tsm-sustainability-assessment-new',
    templateUrl: './sustainability-assessment-new.component.html',
    styleUrls: ['./sustainability-assessment-new.component.sass']
})
export class SustainabilityAssessmentNewComponent implements OnInit {
    configs: Array<ComponentConfig> = [];
    model: any = {};
    context: DynamicContext;
    @ViewChild("form", { static: false }) form: DynamicFormComponent;
    confirmButton: ButtonPropsType;
    supplierTsmId: string;
    countryServed: any;
    marketUnit: string;
    countryGroupSa: string;
    supplierName: string;
    saveError: boolean;
    taskId: string;
    saveErrorAlertProps: AlertPropsType = {
        type: "alert-error",
        message: "There is a technical issue, please try again or contact us.",
        icon: "assets/images/icon-error.svg",
        iconFill: "#cc1e32",
        iconClose: "assets/images/icon-cross-blue.svg"
    };
    supplierTsmIdEmitSubscription: Subscription;
    buyerId: string;
    buyerUserTsmId: string;
    backId: string;
    hasParent: boolean = false;
    updatedCountryServedArray: string[];
    usersFromBackend: any[] = [];
    successCountries = [];
    failCountries = [];
    bothValues = {};
    supplierProfileCountryServed: any;
    supplier: any;
    parent: any;
    childTsmId: string = '';
    isSaUpdateRequested: boolean = false;
    servedData: any = [];
    isLatam: boolean = false;
    response: any = [];

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private metadataService: MetadataService,
        private supplierService: SupplierService,
        private supplierGeneralProfileService: SupplierGeneralProfileService,
        private blacklistService: BlacklistService,
        private esgQuestionnaireService: EsgQuestionnaireService,
        public authService: AuthService,
        private supplierRelationshipService: SupplierRelationshipService,
        private adminBoardService: AdminBoardService,
        private HttpService: HttpService
    ) {
        this.handleAddNewPoCEvent = this.handleAddNewPoCEvent.bind(this);
        this.handleSupplierPoCChangeEvent = this.handleSupplierPoCChangeEvent.bind(this);
    }

    hasAnySupplierPermission(user) {
        try {
            const extensionData = JSON.parse(user.extension_tsm_TSM);
            return extensionData["2"].includes(1) || extensionData["2"].includes(2);
        }
        catch {
            console.log(`Error parsing extension data for ${user.userName}.`);
            return false;
        }
    }

    getSupplierPermissionByExtension(user) {
        const extensionData = JSON.parse(user.extension_tsm_TSM);
        if (extensionData && extensionData["2"] && Array.isArray(extensionData["2"])) {
            if (extensionData["2"].includes(2)) {
                return "supplierAdmin";
            }
            else if (extensionData["2"].includes(1)) {
                return "generalSupplier";
            }
        }
    }

    getFirstUserAlphabeticallyByUserName(users) {
        const result = _.orderBy(users, ["userName"]);
        return result[0];
    }

    getSelectedOrganisationUserId(users) {
        const parsedUsers = _.groupBy(
            users.map(u => ({ ...u, role: this.getSupplierPermissionByExtension(u) })),
            u => u.role
        );

        if (parsedUsers.supplierAdmin) {
            return this.getFirstUserAlphabeticallyByUserName(parsedUsers.supplierAdmin).userId;
        }

        if (parsedUsers.generalSupplier) {
            return this.getFirstUserAlphabeticallyByUserName(parsedUsers.generalSupplier).userId;
        }
    }

    async setOrganisationUsers() {
        const tmpUserResult = await this.adminBoardService.getAdminBoardLog(this.supplierTsmId);

        if (Array.isArray(tmpUserResult) && tmpUserResult.length > 0) {
            this.usersFromBackend = tmpUserResult.filter(u => this.hasAnySupplierPermission(u));
            //remove unregistered users
            this.usersFromBackend = this.usersFromBackend.filter(u => u.userId !== undefined);

            const selectedUserId = this.getSelectedOrganisationUserId(this.usersFromBackend)

            const organisationUsers = this.usersFromBackend.map((u) => ({
                Code: u.userId,
                Text: u.firstName && u.lastName ? `${u.firstName} ${u.lastName}` : u.userName,
                Selected: u.userId === selectedUserId,
                Email: u.userName
            }))

            this.context.set(Context.ORGANISATION_USERS, organisationUsers);
        }
    }

    handleAddNewPoCEvent() {
        const model = this.context.getModel();
        model.primaryContactFirstName = "";
        model.primaryContactLastName = "";
        model.primaryContactEmail = "";

        const pocComponent = this.context.getComponent(SUPPLIER_POC_COMPONENT);
        // this cast is fine since it can only be broken by changing the form-metadata in the backend
        (pocComponent as SingleChoiceDropdownComponent).choose(null, null);

        this.form.updateFormValues();
    }

    handleSupplierPoCChangeEvent(newPoC) {
        const model = this.context.getModel();

        const user = _.find(this.usersFromBackend, u => u.userId === newPoC);

        if (user) {
            model.primaryContactFirstName = user.firstName;
            model.primaryContactLastName = user.lastName;
            model.primaryContactEmail = user.userName;
        }

        this.form.updateFormValues();
        this.enableNameControls();
    }

    createDynamicContext() {
        this.context = new DynamicContext();
    }

    async ngOnInit() {
        window.scrollTo(0, 0);
        // set params
        const queryParams = this.route.snapshot.queryParams;
        this.supplierTsmId = queryParams.supplierTsmId;
        this.countryServed = queryParams.countryServed;
        this.isSaUpdateRequested = queryParams.isSaUpdateRequested;
        if (typeof this.countryServed === 'string') { // refresh page will be string
            this.countryServed = this.countryServed.split(',');
        }
        this.createDynamicContext();
        const userInfo = this.authService.getLoginUser();
        this.buyerId = userInfo.tsmId;
        this.buyerUserTsmId = userInfo.userId;
        this.backId = this.supplierTsmId;
        // re-get supplier and parent because countryServed will change when click 'view details' or 'confirm'
        this.supplier = {};
        const parent = await this.supplierRelationshipService.getParentChildRelation(this.supplierTsmId);
        if (parent && parent.parentTsmId && parent.status === 'MutuallyConfirmed') {
            this.hasParent = true;
            const promisesResult = await Promise.all([
                this.supplierGeneralProfileService.getSupplierById(this.supplierTsmId),
                this.supplierGeneralProfileService.getSupplierById(parent.parentTsmId)
            ]);
            this.supplier = promisesResult[0];
            this.parent = promisesResult[1];
            this.supplierTsmId = parent.parentTsmId;
        }else{
            this.supplier = await this.supplierGeneralProfileService.getSupplierById(this.supplierTsmId);
        }
        const promises = await Promise.all([
            this.blacklistService.returnBlacklist(this.buyerId),
            this.metadataService.getAssessmentFormConfig(),
            this.setOrganisationUsers()
        ]);
        // set dynamic
        const blacklist = promises[0];
        const formMetadata = promises[1];
        this.context.set("blacklist", blacklist);
        this.context.on("BUTTON:EVENT::add-new-poc", this.handleAddNewPoCEvent);
        this.form.subscribeToModelChange(SUPPLIER_POC_COMPONENT, this.handleSupplierPoCChangeEvent);
        if (this.hasParent) {
            this.countryServed.forEach(i => {
                if (!this.supplier.countryServed.includes(i)) {
                    this.supplier.countryServed.push(i)
                    this.updatedCountryServedArray = this.supplier.countryServed;
                }
            })
        }
        const servedDataUrl = `${environment.tsmBackendServiceURL}/countries/activeCountryServed`;
        this.servedData = await this.HttpService.GetPromise(servedDataUrl);
        this.response = this.servedData.filter(i => (i.marketUnit === 'LATAM')).map(j => j.tsmCode);
        this.response.forEach(countryCode => {
            if (this.countryServed.includes(countryCode)) {
                this.isLatam = true;
            }
        });
        if (this.isLatam) {
            formMetadata[3].fieldset[0].dataSource.name = 'request_purpose_latam';
        }else{
            formMetadata[3].fieldset[0].dataSource.name = 'request_purpose';
        }
        this.configs = formMetadata;
        // initButton
        this.initButton();
        this.route.queryParams.subscribe((params) => {
            if (this.hasParent) {
                this.supplierName = this.supplier.supplierName;
            } else {
                this.supplierName = params.supplierName;
            }
            if (params.taskId) {
                this.taskId = params.taskId;
            }
        });
    }

    async initButton() {
        this.confirmButton = {
            label: "Confirm & Send To Supplier",
            color: "white",
            fontSize: 16,
            backgroundColor: "blue",
            borderColor: "blue",
            height: 48,
            width: 280,
            padding: "10px",
            onClick: () => {
                this.submitForm();
            }
        };
    }

    async submitForm() {
        if (this.form.validate()) {
            this.confirmButton.isProcessing = true;
            const values = this.form.values();
            Object.assign(values, {
                supplierTsmId: (this.hasParent ? this.parent.supplierTsmId : this.supplier.supplierTsmId),
                host: window.location.origin,
                buyerTsmId: this.buyerId,
                buyerUserTsmId: this.buyerUserTsmId,
                supplierName: (this.hasParent ? this.parent.supplierName : this.supplier.supplierName),
                defaultTaskType: "assessment"
            });

            if(this.isSaUpdateRequested){
            Object.assign(values, {
                            saUpdateRequested: true
                        });
            }
            else{
            Object.assign(values, {
                          saUpdateRequested: false
                                    });
            }
            if (this.hasParent) {
                Object.assign(values, {
                    subsidiaryTsmId: this.supplier.supplierTsmId,
                    subsidiaryName: this.supplier.supplierName
                });
            }
            this.route.queryParams.subscribe((params) => {
                if (params.taskId) {
                    Object.assign(values, {
                        taskId: params.taskId,
                        primaryContactEmail: params.primaryContactEmail,
                    });
                }
            });
            if (values.taskId) {
                values.countryServed = this.countryServed;
                const response = await this.supplierService.addNew(values);
                // redirects user after successful API call
                if (response.text === "OK") {
                    this.successSupplierService('');
                } else {
                    this.failSupplierService(response);
                }
            } else {
                const checkParams = {
                    "supplierTsmId": this.supplier.supplierTsmId,
                    "countryServed": this.countryServed,
                    "supplierName": this.supplier.supplierName
                };
                let existActiveRequest = await this.checkActiveRequest(checkParams);
                if (existActiveRequest) {
                    this.confirmButton.isProcessing = false;
                    let errorMsg = "The request already existed, you can't send the duplicate request.";
                    this.showErrorAlert(errorMsg);
                } else {
                    // redirecting code to improve the performance and redirecting after all the validation check
                    this.router.navigate(['/supplier-profile', this.backId], {
                        queryParams: { countryServed: this.countryServed },
                    });
                    // addNew
                    let createTaskCountries = this.countryServed;
                    if (countryList.Data.countryGroupSa[createTaskCountries[0]] == '') { // countries no Sa
                        let responseList = [];
                        for (let a = 0; a < createTaskCountries.length; a++) {
                            values.countryServedLang = createTaskCountries[a];
                            values.countryServed = createTaskCountries[a];
                            const response = await this.supplierService.add(values);
                            responseList.push(response);
                        }
                        this.processResp_noSa(responseList, createTaskCountries);
                    } else { // countries with Sa
                        let typeList = []; // get sa list and one represent country
                        let typeRepresentCountries = [];
                        let CSObject = {};
                        for (let a = 0; a < createTaskCountries.length; a++) {
                            let groupSa = countryList.Data.countryGroupSa[createTaskCountries[a]];

                            if (typeList.indexOf(groupSa) < 0) {
                                typeList.push(groupSa);
                                typeRepresentCountries.push(createTaskCountries[a]);
                                if(CSObject.hasOwnProperty(groupSa)){
                                    let oldArray = CSObject[groupSa];
                                    oldArray.push(createTaskCountries[a]);
                                }else{
                                    let countryServedLang = [];
                                    countryServedLang.push(createTaskCountries[a]);
                                    CSObject[groupSa] = countryServedLang;
                                }
                            }else{
                                if(CSObject.hasOwnProperty(groupSa)){
                                    let oldArray = CSObject[groupSa];
                                    oldArray.push(createTaskCountries[a]);
                                }else{
                                    let countryServedLang = [];
                                    countryServedLang.push(createTaskCountries[a]);
                                    CSObject[groupSa] = countryServedLang;
                                }
                            }

                        }
                        let responseList = [];
                        for (let b = 0; b < typeList.length; b++) {
                            let csTypeKey = typeList[b];
                            values.countryServed = typeRepresentCountries[b];
                            values.countryServedLang = CSObject[csTypeKey];
                            const response = await this.supplierService.add(values);
                            responseList.push(response);
                        }
                        this.processResp_sa(responseList, typeList, typeRepresentCountries, createTaskCountries);
                    }
                }
            }
        }
    }

    async checkActiveRequest(params) {
        let isActive = false;
        // 1.get latest task record by supplierTsmId and countryServed
        let esgStatusResponse = await this.supplierService.getESGStatus(params.supplierTsmId, params.countryServed);
        if (esgStatusResponse && Object.keys(esgStatusResponse.assessment).length > 0) {
            let latestStatus = esgStatusResponse?.status;
            if (latestStatus == "Validation Completed") {
                // 2. if latest task record esgStatus="Validation Completed", then use taskId to get check whether has status="Update request" in updatesa table
                isActive = await this.supplierService.getActiveUpdateSaRecord(params);
            } else if (latestStatus !== "Cancelled" && latestStatus !== "Terminated") {
                isActive = true;
            }
        }
         return isActive;
    }

    // process for country with no groupSa
    async processResp_noSa(responseList, createTaskCountries) {
        let successResp = [];
        let failResp = [];
        let successRespCountries = [];
        let failRespCountries = [];
        // divide respones into groups
        for (let a = 0; a < responseList.length; a++) {
            let resp = responseList[a];
            if (resp && resp.$metadata && resp.$metadata.httpStatusCode === 200) {
                successResp.push(resp);
                successRespCountries.push(createTaskCountries[a])
            } else {
                failResp.push(resp);
                failRespCountries.push(createTaskCountries[a])
            }
        }
        switch (successResp.length) {
            case responseList.length: // all success
                await this.allSuccess(createTaskCountries);
                break;
            case 0: // all fail
                await this.allfail(failResp);
                break;
            default: // both
                await this.both_process(successRespCountries, createTaskCountries, failRespCountries)
                break;
        }
    }

    async updateSupplierProfile(addCountry) {
        if (!this.hasParent) { // no parent
            const supplierCountryServed = [...new Set(this.supplier.countryServed.concat(addCountry))];
            const newData = {
                countryServed: supplierCountryServed,
                saCountryServed: (this.supplier.saCountryServed ? [...new Set(this.supplier.saCountryServed.concat(addCountry))] : addCountry)
            };
            let result = await this.supplierGeneralProfileService.updateSupplier(this.supplier.supplierTsmId, newData);
            this.updateRecordTime(this.supplier.supplierTsmId, this.supplier.countryServed, addCountry, result);
        } else { // has parent
            const parentCountryServed = [...new Set(this.parent.countryServed.concat(addCountry))];
            const childCountryServed = [...new Set(this.supplier.countryServed.concat(addCountry))];
            const pNewData = {
                countryServed: parentCountryServed,
                saCountryServed: (this.parent.saCountryServed ? [...new Set(this.parent.saCountryServed.concat(addCountry))] : addCountry)
            };
            const cNewData = {
                countryServed: childCountryServed,
                saCountryServed: (this.supplier.saCountryServed ? [...new Set(this.supplier.saCountryServed.concat(addCountry))] : addCountry)
            };
            let pResult = await this.supplierGeneralProfileService.updateSupplier(this.parent.supplierTsmId, pNewData); // for parent
            this.updateRecordTime(this.parent.supplierTsmId, this.parent.countryServed, addCountry, pResult);
            let cResult = await this.supplierGeneralProfileService.updateSupplier(this.supplier.supplierTsmId, cNewData); // for child
            this.updateRecordTime(this.supplier.supplierTsmId, this.supplier.countryServed, addCountry, cResult);
        }
    }

    async updateRecordTime(tsmId, countryServed, updateCountryServed, result) {
        let samePart = countryServed.filter(item => updateCountryServed.includes(item));
        let difference = updateCountryServed.filter(item => !samePart.includes(item))
        if (difference.length !== 0 && result.$metadata.httpStatusCode == 200) {
            await this.supplierGeneralProfileService.recordTime(tsmId, difference)
        }
    }

    async allSuccess(createTaskCountries) {
        await this.updateSupplierProfile(createTaskCountries);
        this.countryServed = createTaskCountries[0];
        this.successSupplierService('');
    }

    async allfail(failResp) {
        this.failSupplierService(failResp[0]);
    }

    async both_process(successRespCountries, createTaskCountries, failInfo) {
        this.successCountries = [];
        this.failCountries = [];
        // get countryList for success and fail in separate
        for (let a = 0; a < createTaskCountries.length; a++) {
            if (countryList.Data.countryGroupSa[createTaskCountries[a]] != failInfo[0]) {
                this.successCountries.push(countryList.Data.mapping[createTaskCountries[a]]);
            } else {
                this.failCountries.push(countryList.Data.mapping[createTaskCountries[a]]);
            }
        }
        let message_success = 'You have successfully sent the Sustainability Assessment request of ' + this.successCountries.join(',') + ' to the Supplier.';
        let message_fail = 'The Sustainability Assessment request of ' + this.failCountries.join(',') + ' haven\'t sent successfully.';
        this.bothValues = {
            text: "bothCase",
            message_success: message_success,
            message_fail: message_fail
        }
        await this.updateSupplierProfile(this.successCountries);
        // jump to buyer page should show success response country
        this.countryServed = successRespCountries[0];
        this.successSupplierService('multi-alert');
    }

    // process for country with groupSa
    async processResp_sa(responseList, typeList, typeRepresentCountries, createTaskCountries) {
        let successResp = [];
        let failResp = [];
        let successRespCountries = [];
        let failTypes = [];
        for (let a = 0; a < responseList.length; a++) {
            let resp = responseList[a];
            if (resp && resp.$metadata && resp.$metadata.httpStatusCode === 200) {
                successResp.push(resp);
                successRespCountries.push(typeRepresentCountries[a])
            } else {
                failResp.push(resp);
                failTypes.push(typeList[a])
            }
        }
        switch (successResp.length) {
            case responseList.length: // all success
                await this.allSuccess(createTaskCountries);
                break;
            case 0: // all fail
                await this.allfail(failResp);
                break;
            default: // both
                await this.both_process(successRespCountries, createTaskCountries, failTypes)
                break;
        }
    }

    private successSupplierService(alert) {
        if (alert && alert == 'multi-alert') {
            setTimeout(() => {
                this.esgQuestionnaireService.eventEmit.emit(this.bothValues);
            }, 3000);
        } else {
            setTimeout(() => {
                this.esgQuestionnaireService.eventEmit.emit('addSuccess');
            }, 3000);
        }
    }

    private failSupplierService(response) {
        this.confirmButton.isProcessing = false;
        let message = "There is a technical issue, please try again or contact us.";
        if (response.code === responseEnum.b2c_email_used.code) {
            message =
                "This email address is already in use. Please provide a different email address for the supplier.";
        } else if (response.code === responseEnum.b2c_invalid_domain.code) {
            message = "Please ensure that the email address doesn't contain any special characters.";
        }
        // alert error if reponse API call fails
        this.showErrorAlert(message);
    }

    showErrorAlert = (message): void => {
        this.saveError = true;
        this.saveErrorAlertProps = {
            type: "alert-requestVerifiedFaild",
            message,
            icon: "assets/images/icon-error.svg",
            iconFill: "#cc1e32",
            iconClose: "assets/images/icon-cross-blue.svg",
            onDismiss: this.onAlertDismiss
        };
        window.scrollTo(0, 0);
    };

    onAlertDismiss = (): void => {
        this.saveError = false;
    };

    private enableNameControls() {
        const firstNameFormControl = this.form?.formGroup?.controls.primaryContactFirstName;
        const lastNameFormControl = this.form?.formGroup?.controls.primaryContactLastName;
        if (firstNameFormControl?.value === '') {
            firstNameFormControl?.enable();
        }
        if (lastNameFormControl?.value === '') {
            lastNameFormControl?.enable();
        }
    }
}
