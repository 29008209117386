import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { EsgReportService } from 'src/app/services/esgReport/esg-report.service';


@Component({
  selector: 'tsm-company-category-filter',
  templateUrl: './company-category-filter.component.html',
  styleUrls: ['./company-category-filter.component.sass']
})
export class CompanyCategoryFilterComponent {
  @Output() valueChanged = new EventEmitter();
  @Input() clearFilter: Subject<boolean>;

  values: any[];
  labelTranslations = {
    controlLabel: '',
    inputPlaceholder: ''
  }
  companyCategory: any[];
  tempcompanyCodeData: any[];

  constructor(private esgReportService: EsgReportService) {}

  async ngOnInit(): Promise<void> {
    let questionnire = localStorage.getItem('Getting Started');
    let resultQuestionnire = JSON.parse(questionnire);
    const keys = Object.keys(resultQuestionnire['Gen6'].data);
    this.companyCategory = keys.map(s=> {return {Code:s,Text:s}});
    this.clearFilter.subscribe(() => this.clear());
  }

  dropdownChanged(selectedValues) {
    this.values = selectedValues;
    this.valueChanged.emit(this.values);
  }

  clear() {
    this.values = [];
    this.companyCategory.forEach(cc => {
      cc.checked = false;
    });
    this.tempcompanyCodeData = [];
    this.esgReportService.getcompanyCategorySubject.next(this.tempcompanyCodeData);
  }

  async saveSectionEvent(e) {
        if (e.length === 0) {
            // this.searchCriteria.companyCode = [];
            this.values = []
            this.tempcompanyCodeData = [];
            this.valueChanged.emit(this.values);
            // this.isDisabled = true;
        }
        else {
            //this.isDisabled = false;
            this.values = e;
            this.valueChanged.emit(this.values);
            this.tempcompanyCodeData = [];
            let questionnire = localStorage.getItem('Getting Started');
            let resultQuestionnire = JSON.parse(questionnire);
            const keys1 = Object.keys(resultQuestionnire['Gen6_1'].data);
            const travel = keys1.map(s => { return { Code: s, Text: s } });
            const keys2 = Object.keys(resultQuestionnire['Gen6_2'].data);
            const telecom = keys2.map(s => { return { Code: s, Text: s } });
            const keys3 = Object.keys(resultQuestionnire['Gen6_3'].data);
            const Facilities = keys3.map(s => { return { Code: s, Text: s } });
            const finalObject = {
              "Facilities & Services": Facilities,
              "IT & Telecom": telecom,
              "Travel Services": travel
            }          

            for (let val of e) {
              if(finalObject[val]) {
                this.tempcompanyCodeData = [...this.tempcompanyCodeData, ...finalObject[val]]
              }
              
            }
            console.log(this.tempcompanyCodeData)
        }

        this.tempcompanyCodeData.sort(function (obj1, obj2) {
            var val1 = obj1.Text;
            var val2 = obj2.Text;
            if (val1 > val2) {
                return 1;
            } else if (val1 < val2) {
                return -1;
            } else {
                return 0;
            }
        });
        this.esgReportService.getcompanyCategorySubject.next(this.tempcompanyCodeData);
    }

  ngAfterContentChecked() {
    if (localStorage.getItem('multiLanguage')) {
      const multilanguageJson = JSON.parse(
        localStorage.getItem('multiLanguage')
      );
      if (multilanguageJson.body != undefined) {
        this.labelTranslations = multilanguageJson.body.main.SaRetriggeringProcessFlow.companyCategory;
      }
    }
  }

}
