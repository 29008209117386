import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { PopUpCustomGridTableComponent } from '../pop-up-custom-grid-table/pop-up-custom-grid-table.component'

@Component({
  selector: 'tsm-grid-table',
  templateUrl: './grid-table.component.html',
  styleUrls: ['./grid-table.component.sass']
})
export class GridTableComponent implements OnInit{
  @Input() suppliersList: any;
  suppliersData: any;
  NgxDatatableModule;
  footerHeight:any
  columns: any = [
    { prop: 'supplierName', name: 'Company Name', isLeftPinnedColumn: true, width: 220 },
    { prop: 'internationalCompanyName', name: 'International Company Name', width: 220 },
    { prop: 'countryLocation', name: 'Company Location', width: 155 },
    { prop: 'countryOfDelivery', name: 'Country of Delivery', width: 180 },
    { prop: 'domainName', name: 'Domain Name', width: 150 },
    { prop: 'category', name: 'Category', width: 150 },
    { prop: 'dunsNumber', name: 'D U N S Number', width: 150 },
    { prop: 'sapId', name: 'SAP ID', width: 100 },
    { prop: 'taxId', name: 'BRN / TAX / VAT ID', width: 180 },
    { prop: 'companyCode', name: 'Company Code', width: 150 },
    { prop: 'migratedTag', name: 'Migrated Tag', width: 120 },
    { prop: 'biBda', name: 'BI / BDA', width: 60 },
    { prop: 'tsmId', name: 'TSM ID', width: 310},
    { prop: 'tsmStatus', name: 'TSM Status', isLeftPinnedColumn: true, width: 100 }
  ];

  allColumns: any = [
    { prop: 'supplierName', name: 'Company Name', isLeftPinnedColumn: true, width: 220 },
    { prop: 'internationalCompanyName', name: 'International Company Name', width: 220 },
    { prop: 'countryLocation', name: 'Company Location', width: 155 },
    { prop: 'countryOfDelivery', name: 'Country of Delivery', width: 180 },
    { prop: 'domainName', name: 'Domain Name', width: 150 },
    { prop: 'category', name: 'Category', width: 150 },
    { prop: 'dunsNumber', name: 'D U N S Number', width: 150 },
    { prop: 'sapId', name: 'SAP ID', width: 100 },
    { prop: 'taxId', name: 'BRN / TAX / VAT ID', width: 180 },
    { prop: 'companyCode', name: 'Company Code', width: 150 },
    { prop: 'migratedTag', name: 'Migrated Tag', width: 120 },
    { prop: 'biBda', name: 'BI / BDA', width: 60 },
    { prop: 'tsmId', name: 'TSM ID', width: 310},
    { prop: 'tsmStatus', name: 'TSM Status', isLeftPinnedColumn: true, width: 100 }
  ];

  

  constructor(private router: Router,public ngbModalService: NgbModal,) { }

  ngOnInit(): void {
    const suppliersData= this.formatSupplierList(this.suppliersList)
    this.suppliersData = suppliersData;
  }

  openPopUp(){
    const modalReference = this.ngbModalService.open(PopUpCustomGridTableComponent);
    modalReference.componentInstance.allColumns = this.allColumns;
    modalReference.componentInstance.columns = this.columns
    modalReference.componentInstance.dataEmitted.subscribe((selectedColumns) => {
      this.columns=selectedColumns
      modalReference.close();
    });
  }

  ngOnChanges(){
    const suppliersData= this.formatSupplierList(this.suppliersList)
    this.suppliersData = suppliersData;
    if(suppliersData.length>0){
      this.footerHeight=15
    }else{
      this.footerHeight=0
    }
  }

  openSupplier(id: string) {
    this.router.navigate(["/supplier-profile", id]);
  }

  async getformattedSupplierList(supplierList){
    const supplierData=await this.formatSupplierList(supplierList)
    return supplierData
  }


  formatSupplierList(supplierList){
    const categoryList = localStorage.getItem("categoryList");
    const category = JSON.parse(categoryList);
    const codeToTextMap: Record<number, string> = category.reduce((acc, item) => {
      acc[item.Code] = item.Text;
      return acc;
    }, {});
    const formattedSuppliersList= supplierList.map((supplier) => ({
      ...supplier,
      category: codeToTextMap[supplier.category],
    }));
    return formattedSuppliersList
  }



}
