import { Component, Input, OnInit } from "@angular/core";
import { SubheaderPropsType } from "../../models/SubheaderProps";
import { TranslateService } from '@ngx-translate/core';
import { SystemAdminService } from "src/app/services/systemAdmin/system-admin.service";
import { Subscription } from "rxjs";
import { AlertPropsType } from "@lsl16/sustainability-shared-components";
import { AuthService } from "src/app/services/auth/auth.service";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { PopUpConsentRenewalComponent } from "src/app/components/pop-up-consent-renewal/pop-up-consent-renewal.component";
import { ProfileDetailsComponent } from "src/app/components/profile-details/profile-details.component";
import { ModalService } from "src/app/services/modal/modal.service";
import { ModalConfig, ModalType } from "src/app/services/modal/types";
import { consentRenewalModalData } from "../pop-up-consent-renewal/consentRenewalData";
import { SupplierService } from "src/app/services/supplier/supplier.service";
@Component({
    selector: "tsm-subheader",
    templateUrl: "./subheader.component.html",
    styleUrls: ["./subheader.component.sass"]
})
export class SubheaderComponent implements OnInit {
    @Input() props: SubheaderPropsType;
    welcomeMessage: string;
    requestSubmission: Subscription;
    showAlert: boolean = false;
    hasValidRole: boolean = false;
    alertProps: AlertPropsType;
    subHeaderJson: any;
    buttonJson: any = {};
    headingPrefixLabel: any = '';
    getHeadingLabel: any = '';
    supplierOptionsCard: any;
    isConsentRenewal: any;
    countryServed: any;
    consentRenewalModalConfig: { data: ModalType } & ModalConfig = {
        data: consentRenewalModalData,
        type: "consent-renewal-data",
        options: {}
    };
    consentRenewalPageHeading: any;
    countryServedName: any;
    allCountry: any;
    countryGroupSa: any;
    personalDataRemovedFlag = false;
    /**
     * Description: generates a welcome message string based on user's current hour of day.
     * Params: none
     * Output: none
     */
    generateWelcomeMessage = (): void => {
        const dateNow = new Date();
        const hourNow = dateNow.getHours();
        const timeMessageData: [number, string][] = [
            [18, "GOOD EVENING"],
            [12, "GOOD AFTERNOON"],
            [0, "GOOD MORNING"]
        ];
        for (let entry of timeMessageData) {
            if (hourNow >= entry[0]) {
                this.welcomeMessage = entry[1];
                break;
            }
        }
    };

    constructor(
        private translate: TranslateService,
        private systemAdminService: SystemAdminService,
        public authService: AuthService,
        private activatedRoute: ActivatedRoute,
        private ngbModalService: NgbModal,
        private modalService: ModalService,
        private supplierService: SupplierService,
        public router: Router,
    ) {
        /* subscribe to send request success events */
        this.successfulRequestSubscription();
    }

    ngOnInit(): void {
        this.consentRenewalPageHeading = "";
        this.generateWelcomeMessage();
        this.handleManageUsersButtonVisibility();
        this.translate.get('supplier.body.main.optionsCard').subscribe((res)=>{
            this.supplierOptionsCard = res;
        })
    }
    async openConsentRenewalPopup(): Promise<void> {
        this.consentRenewalModalConfig.options = this.modalService.getOptions(
            this.consentRenewalModalConfig.type
        );
        const modalReference = this.ngbModalService.open(PopUpConsentRenewalComponent, this.consentRenewalModalConfig.options);
        // modalReference.componentInstance.confirmType = confirmType;
        let tsmRoot = <HTMLElement>document.getElementsByTagName('tsm-root')[0];
        if (tsmRoot) {
          tsmRoot.setAttribute('aria-hidden', 'false');
        }
    }

    ngOnChanges(): void {
        this.activatedRoute.queryParams.subscribe(params => {
            this.isConsentRenewal = params['isConsent'];
        });
        this.activatedRoute.queryParams.subscribe(params => {
            this.countryServed = params['countryServed'];
        });
        if(this.isConsentRenewal === 'true'){
            this.redirectToConsentRenewalPage();
        }
        this.showAlert = false;
    }

    async redirectToConsentRenewalPage() {
        const questionnaireId = this.extractIdFromUrl(window.location.href);
        const buyerId: string = "c8aa5c00-588a-44d6-a08c-071dc9219cbe";
        let assignedToEmail;
        let supplierTsmId;
        let latestTaskId;
        this.activatedRoute.queryParams.subscribe(params => {
            assignedToEmail = params['assignedToEmail'];
        });
        this.activatedRoute.queryParams.subscribe(params => {
            supplierTsmId = params['supplierTsmId'];
        });
        this.activatedRoute.queryParams.subscribe(params => {
            latestTaskId = params['latestTaskId'];
        });
        const getByquestionnaireIdResponse = await this.supplierService.getByquestionnaireId(questionnaireId, this.countryServed, assignedToEmail);
        // console.log("getByquestionnaireIdResponse### in subheader", getByquestionnaireIdResponse);
        const getQuestionnaireTask = await this.supplierService.getQuestionnaireTask(buyerId, supplierTsmId, questionnaireId, this.countryServed);
        // console.log("getQuestionnaireTask### in subheader", getQuestionnaireTask);
        if (getQuestionnaireTask) {
            for (let item of getQuestionnaireTask) {
                if (item.taskId === latestTaskId) {
                    if(item.personalDataRemoved) {
                        this.personalDataRemovedFlag = true
                    }
                    break;
                }
            }
        }
        this.allCountry = JSON.parse(localStorage.getItem('countryList'))?.servedCountryList;
        if(this.allCountry){
            this.allCountry.forEach(element => {
                if(element.tsmCode === this.countryServed){
                    this.countryServedName = element.countryServedName;
                    this.countryGroupSa = element.countryGroupSa? element.countryGroupSa: element.tsmCode;
                }
            });
        }
        const renewIDconsentSentOn = `renewIDconsentSentOn_${this.countryGroupSa}`;
        const declineConsentSentOn = `declineIDconsentSentOn_${this.countryGroupSa}`;
        if(this.personalDataRemovedFlag === true || getByquestionnaireIdResponse[renewIDconsentSentOn] ||  getByquestionnaireIdResponse[declineConsentSentOn]) {
            this.router.navigate(["/supplier-profile", supplierTsmId]);
        } else {
            this.openConsentRenewalPopup();
        }
    }

    extractIdFromUrl(url: string): string | null {
        const regex = /\/survey-supplier-i-d\/([a-zA-Z0-9-]+)/;
        const match = url.match(regex);
        return match ? match[1] : null;
    }

    getHeading(value: string) {
            return value
    }

    handleManageUsersButtonVisibility(): void {
        const userRole = this.authService.getRole();
        this.hasValidRole = userRole === 'supplier' && (this.authService.isSupplierAdmin() || this.authService.isGeneralSupplier());
    }

    successfulRequestSubscription = () => {
        this.requestSubmission = this.systemAdminService.eventEmit.subscribe((value: any, message: any) => {
            if (value.type === "addSuccess") {
                this.showAlert = true;
                this.alertProps = {
                    type: "alert-requestVerifiedSuccess",
                    message: value.message,
                    icon: "assets/images/icon-tick.svg",
                    iconFill: "#376803",
                    iconClose: "assets/images/icon-cross-green.svg",
                    onDismiss: this.onAlertDismiss
                };
                window.scrollTo(0, 0);
            } else if(value.type === "addError") {
                this.setAlertErrorMessage(value.message)
            }
        });
    };

    onAlertDismiss = (): void => {
        this.showAlert = false;
    };

    setAlertErrorMessage = (message): void => {
        this.showAlert = true;
        this.alertProps = {
          type: 'alert-requestVerifiedFaild',
          message: message,
          icon: 'assets/images/icon-error.svg',
          iconFill: '#cc1e32',
          iconClose: 'assets/images/icon-cross-blue.svg',
          onDismiss: this.onAlertDismiss,
        };
        window.scrollTo(0, 0);
      };

    ngAfterContentChecked() {
        this.getHeadingLabel = ""; //  Reset the Heading on loading a new page
        if (localStorage.getItem("multiLanguage")) {
            const multiLanguageJson = JSON.parse(localStorage.getItem("multiLanguage"));
            if (multiLanguageJson.body != undefined && this.props != undefined) {
                this.supplierOptionsCard = multiLanguageJson.body.main.subHeader
                this.subHeaderJson = multiLanguageJson.body.main.subHeader;
                this.consentRenewalPageHeading = this.subHeaderJson.heading.consentRenewalPageHeading;
                let headingLabel = this.getHeading(this.props.heading);
                if (this.subHeaderJson.heading.hasOwnProperty(headingLabel)) {
                    this.getHeadingLabel = this.subHeaderJson.heading[headingLabel];
                }
                this.headingPrefixLabel = this.subHeaderJson.headingPrefix[this.props.headingPrefix]
                this.buttonJson = multiLanguageJson.body.sharedComponent.buttonLable;
                if (this.buttonJson.hasOwnProperty(this.props.manageUsersButtonProps?.tooltipText)) {
                    this.buttonJson.manageUsersTooltip = this.buttonJson[this.props.manageUsersButtonProps.tooltipText];
                }
            }
        }
        this.allCountry = JSON.parse(localStorage.getItem('countryList'))?.servedCountryList;
        if(this.allCountry){
            this.allCountry.forEach(element => {
                if(element.tsmCode === this.countryServed){
                    this.countryServedName = element.countryServedName;
                }
            });
        }
    }

    ngOnDestroy() {
        // Unsubscribe to prevent memory leaks
        if (this.requestSubmission) {
            this.requestSubmission.unsubscribe();
        }
    }
}
