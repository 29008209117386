<div class="onbarding shadow rounded-0 position-relative border">
    <div class="row ms-3 tittle-heard">
        <div class="col-8">
            <div class="title-container mt-3">
                <div class="title">Onboarding</div>
                <div class="sub_title_name">Gather key supplier details to begin using their goods and/or services
                    (includes Sustainability Assessment)</div>
            </div>
        </div>
    </div>
    <div *ngIf="!onbordingDataIf">
        <div class="row mt-3  ms-3">
            <div class="col-2">
                <div class="row">
                    <div class="col-12 display-flex">
                        <label class="select-label line-ht-1" for="marketUnit">Select Market Unit</label>
                        <div class="col-7 ps-0">
                            <tsm-select-input id="Mu" class="allMu" [drapDownData]="tempMarketUnit" [multiple]="false"
                                [placeholder]="'Select'" (SingleDateSend)="getSingleDate($event)">
                            </tsm-select-input>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-3">
                <div class="row">
                    <div class="col-12 display-flex">
                        <label class="select-label line-ht-1" for="tempLocation">Select Country/ies</label>
                        <div class="col-7 ps-0">
                            <tsm-select-input id="onboardingCountry" class="allCountry" [refresh]="true"
                                [drapDownData]="tempLocation" [multiple]="true"
                                (multipleDateSend)="getcounterDate($event)" [placeholder]="'Select'">
                            </tsm-select-input>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-3">
                <div class="row">
                    <div class="col-12 display-flex">
                        <label class="select-label line-ht-1" for="tempCompanyCode">Company Code</label>
                        <div class="col-7 ps-0">
                            <tsm-select-input id="countryCode" class="allCompanyCode" [refresh]="true"
                                [drapDownData]="tempCompanyCode" [multiple]="true" [placeholder]="'Select'"
                                (multipleDateSend)="getCompanyCode($event)">
                            </tsm-select-input>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-2">
                <div class="row">
                    <div class="col-12 display-flex">
                        <label class="select-label line-ht-1" for="tempSapId">SAP ID</label>
                        <div class="col-7 ps-0">
                            <tsm-select-input id="sapId" class="allSapId" [refresh]="true" [drapDownData]="tempSapId"
                                [multiple]="true" (multipleDateSend)="getSapId($event)" [placeholder]="'Select'">
                            </tsm-select-input>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-2">
                <div class="row">
                    <div class="col-12 display-flex">
                        <label class="select-label line-ht-1" for="tempLocation">Status</label>
                        <div class="col-7 ps-0">
                            <tsm-select-input id="status" class="allCountry" [drapDownData]="stateData"
                                [multiple]="true" (multipleDateSend)="getstatusleDate($event)" [placeholder]="'Select'">
                            </tsm-select-input>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-3  ms-3 table-header">
            <div class="col-1">MARKET UNIT</div>
            <div class="col-2">
                COUNTRIES SERVED
                <img class="icon-size" placement="bottom-right" tooltipClass="manage-user-tooltip-class"
                    triggers="click:blur" src="assets/icons/info-blue.svg" alt="icon-info"
                    [ngbTooltip]="tipCountries" />
                <ng-template #tipCountries>
                    <div class="tip-inner-content">
                        Location of goods and services provided
                    </div>
                </ng-template>
            </div>
            <div class="col-1">REQUESTOR</div>
            <div class="col-2">REQUEST DATE</div>
            <div class="col-2">COMPANY CODE & SAP ID</div>
            <div class="col-2">
                STATUS
                <img class="icon-size" src="assets/icons/info-blue.svg" (click)="showStatusPopup()" alt="" />
            </div>
            <div class="col-2">ACTIONS</div>
        </div>
        <div class="row ms-3 header-border"></div>
        <div class="content-max">
            <div *ngFor="let item of onboaredDataList">
                <div class="row mt-3  ms-3 fs-12">
                    <div class="col-1 onboarding-content" placement="bottom-right"
                        tooltipClass="manage-user-tooltip-class" triggers="hover" [ngbTooltip]="tipmarketunit"
                        container="body">{{item.marketunit}}
                        <ng-template #tipmarketunit>
                            <div class="tip-inner-content">
                                {{item.marketunit}}
                            </div>
                        </ng-template>
                    </div>
                    <div class="col-2 onboarding-content" placement="bottom-right"
                        tooltipClass="manage-user-tooltip-class" triggers="hover" [ngbTooltip]="tiponboardedcountry"
                        container="body">{{item.onboardedcountry}}
                        <ng-template #tiponboardedcountry>
                            <div class="tip-inner-content">
                                {{item.onboardedcountry.toString()}}
                            </div>
                        </ng-template>
                    </div>
                    <div class="col-1 onboarding-content" placement="bottom-right"
                        tooltipClass="manage-user-tooltip-class" triggers="hover" [ngbTooltip]="tipRequestor"
                        container="body">
                        {{item.requestor}}
                        <ng-template #tipRequestor>
                            <div class="tip-inner-content">
                                {{item.requestor}}
                            </div>
                        </ng-template>
                    </div>
                    <div class="col-2 onboarding-content" placement="bottom-right"
                        tooltipClass="manage-user-tooltip-class" triggers="hover" [ngbTooltip]="tipRequsteddate"
                        container="body">{{item.requesteddate | date: 'MM/dd/yyyy'}}
                        <ng-template #tipRequsteddate>
                            <div class="tip-inner-content">
                                {{item.requesteddate}}
                            </div>
                        </ng-template>
                    </div>
                    <div class="col-2 onboarding-content" placement="bottom-right"
                        tooltipClass="manage-user-tooltip-class" triggers="hover" [ngbTooltip]="tipcompanySapCode"
                        container="body"> {{ item.companyCodeAndSapId[0].Text |uppercase }}
                        <ng-template #tipcompanySapCode>
                            <div class="tip-inner-content-company">
                                <p *ngFor="let i of item.companyCodeAndSapId"><strong>{{ i.Text |uppercase }}</strong>
                                </p>
                            </div>
                        </ng-template>
                    </div>
                    <div class="col-2">
                        <div class="width-100">
                            <div class="colstatus colstatus-common status-color-orange" style="margin-bottom: 2px;"
                                [ngClass]="{'status-color-green':item.level==6,'status-color-blue':item.level==1,'status-color-red':item.level=='cancel'}">
                                {{item.status}}
                            </div>
                            <div class="display-flex">
                                <div class="status-bar-common status-bar-ready"
                                    [ngClass]="{'status-bar-process':item.level&lt;6,'status-bar-process':item.level>1,'status-bar-complete':item.level == 6,'status-bar-empty':item.level==1}">
                                </div>
                                <div class="status-bar-common status-bar-ready"
                                    [ngClass]="{'status-bar-process':item.level&lt;6,'status-bar-process':item.level>=2,'status-bar-complete':item.level == 6}">
                                </div>
                                <div class="status-bar-common status-bar-ready"
                                    [ngClass]="{'status-bar-process':item.level&lt;6,'status-bar-process':item.level>=3,'status-bar-complete':item.level == 6}">
                                </div>
                                <div class="status-bar-common status-bar-ready"
                                    [ngClass]="{'status-bar-process':item.level&lt;6,'status-bar-process':item.level>=4,'status-bar-complete':item.level == 6}">
                                </div>
                                <div class="status-bar-common status-bar-ready"
                                    [ngClass]="{'status-bar-process':item.level&lt;6,'status-bar-process':item.level>=5,'status-bar-complete':item.level == 6}">
                                </div>
                                <div class="status-bar-common status-bar-ready"
                                    [ngClass]="{'status-bar-process':item.level&lt;6,'status-bar-process':item.level>=6,'status-bar-complete':item.level == 6}">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="statusFlag" class="col-2" style="cursor:pointer" (click)="jump(item)">
                        <a class="a-button text-overflow onboarding-content" style="color:#0071EB">View or Edit Details
                        </a>
                        <svg width="12" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M10.8856 9.13476V11.5564C10.8856 12.1567 10.6725 12.6701 10.2461 13.0964C9.81984 13.5227 9.3065 13.7358 8.70614 13.7358H2.40993C1.80957 13.7358 1.29623 13.5227 0.869928 13.0964C0.443622 12.6701 0.230469 12.1567 0.230469 11.5564V5.26016C0.230469 4.6598 0.443622 4.14647 0.869928 3.72016C1.29623 3.29386 1.80957 3.0807 2.40993 3.0807H7.7375C7.80813 3.0807 7.86614 3.10341 7.91155 3.14881C7.95695 3.19422 7.97966 3.25224 7.97966 3.32287V3.80719C7.97966 3.87782 7.95695 3.93584 7.91155 3.98125C7.86614 4.02665 7.80813 4.04935 7.7375 4.04935H2.40993C2.07696 4.04935 1.79191 4.16791 1.55479 4.40503C1.31768 4.64215 1.19912 4.92719 1.19912 5.26016V11.5564C1.19912 11.8894 1.31768 12.1744 1.55479 12.4115C1.79191 12.6486 2.07696 12.7672 2.40993 12.7672H8.70614C9.03912 12.7672 9.32416 12.6486 9.56128 12.4115C9.7984 12.1744 9.91695 11.8894 9.91695 11.5564V9.13476C9.91695 9.06413 9.93966 9.00611 9.98506 8.9607C10.0305 8.9153 10.0885 8.8926 10.1591 8.8926H10.6434C10.7141 8.8926 10.7721 8.9153 10.8175 8.9607C10.8629 9.00611 10.8856 9.06413 10.8856 9.13476ZM13.7915 2.59638V6.47097C13.7915 6.60215 13.7436 6.71566 13.6478 6.81152C13.5519 6.90737 13.4384 6.9553 13.3072 6.9553C13.1761 6.9553 13.0625 6.90737 12.9667 6.81152L11.6348 5.47962L6.70074 10.4137C6.65029 10.4641 6.59227 10.4894 6.52668 10.4894C6.4611 10.4894 6.40308 10.4641 6.35263 10.4137L5.48993 9.55097C5.43948 9.50052 5.41425 9.44251 5.41425 9.37692C5.41425 9.31134 5.43948 9.25332 5.48993 9.20287L10.424 4.26881L9.09209 2.93692C8.99623 2.84106 8.94831 2.72755 8.94831 2.59638C8.94831 2.46521 8.99623 2.3517 9.09209 2.25584C9.18795 2.15998 9.30146 2.11206 9.43263 2.11206H13.3072C13.4384 2.11206 13.5519 2.15998 13.6478 2.25584C13.7436 2.3517 13.7915 2.46521 13.7915 2.59638Z"
                                fill="#0071EB" />
                        </svg>
                    </div>
                </div>
                <div class="row ms-3 header-border" style="padding-top: 1rem"></div>
            </div>
        </div>
    </div>
    <div *ngIf="!onbordingDataIf" class="row mb-4"></div>
</div>