import { SupplierClassificationService } from '@lsl16/sustainability-shared-components';
import { Injectable } from '@angular/core'
import { UntypedFormGroup } from '@angular/forms'

import {
    CustomDatasource,
    ComponentConfig,
    DynamicContext
} from "@lsl16/sustainability-shared-components";
import { Context } from './constants';
import { DictionaryService } from '@lsl16/sustainability-shared-components';

@Injectable()
export default class CustomDatasources {

    constructor(private supplierClassificationService: SupplierClassificationService, private dictionaryService: DictionaryService) { }

    @CustomDatasource('organisationUsers')
    organisationUsers(config: ComponentConfig, context: DynamicContext, formGroup: UntypedFormGroup) {
        return async (params: any): Promise<any> => {
            const orgUsers = context.getValue(Context.ORGANISATION_USERS);

            if (orgUsers) {
                return Promise.resolve(orgUsers);
            }

            return Promise.resolve([]);
        }
    }

    @CustomDatasource('allsupplierclassifications')
    allsupplierclassifications(config: ComponentConfig, context: DynamicContext, formGroup: UntypedFormGroup) {
        return async (params: any): Promise<any> => {
            return await this.supplierClassificationService.getCategories();
        }
    }

    @CustomDatasource('update_reason')
    updateReason(config: ComponentConfig, context: DynamicContext, formGroup: UntypedFormGroup) {
        return async (params: any): Promise<any> => {
            return await this.dictionaryService.getDictionaryData(config.dataSource.name);
        };
    }

    @CustomDatasource("category")
    category(config: ComponentConfig, context: DynamicContext, formGroup: UntypedFormGroup) {
        return async (params: any): Promise<any> => {
            let categoryData = JSON.parse(localStorage.getItem('categoryList'));
            return categoryData;
        };
    }

    @CustomDatasource("subCategory")
    subCategory(config: ComponentConfig, context: DynamicContext, formGroup: UntypedFormGroup) {
        return async (params: any): Promise<any> => {
            let subCategoryData = JSON.parse(localStorage.getItem('subCategoryList'));
            return subCategoryData;
        };
    }

    @CustomDatasource("customMarketUnitList")
    customMarketUnitList(config: ComponentConfig, context: DynamicContext, formGroup: UntypedFormGroup) {
        return async (params: any): Promise<any> => {
            let marketUnitData = JSON.parse(localStorage.getItem('marketUnitList'));
            let updatedMarketUnitList = [];
            for (let i = 0; i < marketUnitData.length; i++) {
                if (marketUnitData[i].Code !== 'LATAM') {
                    updatedMarketUnitList.push(marketUnitData[i])
                }
            }
            return updatedMarketUnitList;
        };
    }
    @CustomDatasource("bibdaScore")
    bibdaScore(config: ComponentConfig, context: DynamicContext, formGroup: UntypedFormGroup) {
        return async (params: any): Promise<any> => {
            let bibdascores = ['Approved', 'Approved with conditions', 'Deleted', 'Expired']
            let bibdaScoreData = bibdascores.map(item => {
                return {
                    "Text": item,
                    "Code": item
                }
            });
            return bibdaScoreData;
        };
    }
}