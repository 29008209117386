import { Injectable } from "@angular/core";
import { GdprLogItem, HttpService } from "@lsl16/sustainability-shared-components";
import { environment } from "src/environments/environment";
import { AuthService } from "../auth/auth.service";

@Injectable({
    providedIn: "root"
})
export class GdprService {
    tsmgetb2cuser = `${environment.tsmBackendServiceURL}/b2c/user`;
    gdprLog: GdprLogItem[] = [];

    constructor(private httpService: HttpService) {}

    /**
     * Search for users based on username
     * @param username
     * @returns
     */
    getUsers = async (username: string): Promise<GdprLogItem> => {
        const url = `${this.tsmgetb2cuser}/${username}`;
        return await this.httpService.GetPromise<GdprLogItem>(url);
    };
}
