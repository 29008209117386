import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { ButtonPropsType, HttpService } from '@lsl16/sustainability-shared-components';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth/auth.service';
import { SupplierGeneralProfileService } from 'src/app/services/supplier-general-profile.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'tsm-pop-up-duplicate',
  templateUrl: './pop-up-duplicate.component.html',
  styleUrls: ['./pop-up-duplicate.component.sass']
})
export class PopUpDuplicateComponent {

  pocUserId: string;
  userEmail: string;

  buttonProps: ButtonPropsType = {
    label: "I acknowledge the changes",
    backgroundColor: "blue",
    color: "white",
    fontSize: 16,
    fontFamily: "Graphik-Medium",
    height: 48,
    borderRadius: 25,
    padding: "16px 24px",
    margin: "0 0 0 2px",
    onClick: async () => {
      await this.continue();
    }
  };
  constructor(public activeModal: NgbActiveModal,
    public supplierGeneralProfileService: SupplierGeneralProfileService,
    public authService: AuthService,
    private http: HttpClient) {}

  async continue(): Promise<any> {

    const b2cUser = this.authService.decodeToken();
    const userEmail = await this.supplierGeneralProfileService.getProfileByEmail(b2cUser.email);
    console.log("useremail is",userEmail.pocEmail);
    await this.supplierGeneralProfileService.setAcknowledgementFlag(userEmail.pocEmail);

      // Close the modal after the API call is successful
    this.activeModal.close();
  }
  onClicked = async () => {
    const baseUrl = `${environment.tsmBackendServiceURL}/merge-profiles/downloadSSPGuide`;
    try {
      const file = await this.http.get(baseUrl, { responseType: "blob" }).toPromise();
      const link = document.createElement('a');
      const url = window.URL.createObjectURL(file);
      link.href = url;
      link.setAttribute('download', 'Single_Supplier_Profile_Guide.pdf');
      link.target = '_blank';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading the guide:', error);
    }
  };
  
}
