import { Component, OnInit,Output, EventEmitter,ViewEncapsulation } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ModalComponent } from "@lsl16/sustainability-shared-components";
import { ModalService } from "src/app/services/modal/modal.service";
import {
    privacyStatementModalData,
    termsOfUseData
} from "src/app/services/modal/privacyStatementModalData";
import { ModalConfig, ModalType } from "src/app/services/modal/types";
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from "../../services/auth/auth.service";
import { EventService } from "src/app/services/EventService.service";

@Component({
    selector: "tsm-footer",
    templateUrl: "./footer.component.html",
    encapsulation: ViewEncapsulation.None,
    styleUrls: ["./footer.component.sass"]
})
export class FooterComponent implements OnInit {
    termsOfUseModalConfig: { data: ModalType } & ModalConfig = {
        data: termsOfUseData,
        type: "terms-of-use",
        options: {}
    };

    /**
     * Constructor
     * @param ngbModalService: NgbModal - ng bootstrap modal service
     * @param modalService: ModalService - custom made service to get the data (options & callback) to use in the modal component
     */
    constructor(
        private ngbModalService: NgbModal,
        private modalService: ModalService,
        private authService: AuthService,
        private translate: TranslateService,
        private eventService: EventService
    ) {}
    supplierFooter = {
        footerLeft: '',
        footerMessage: '',
        footerHerf: '',
        contactButton: '',
        footerRight: ''

    }
    async ngOnInit(): Promise<void> {
        await this.setModalOptions();
        this.translate.get('supplier.body.footer').subscribe((res)=>{
            this.supplierFooter = res;
        })
    }

    /**
     * Description: Sets the modal options
     * @returns: void
     */
    async setModalOptions(): Promise<void> {
        this.termsOfUseModalConfig.options = this.modalService.getOptions(
            this.termsOfUseModalConfig.type
        );
        this.termsOfUseModalConfig.data = await this.modalService.getTermsOfUseData();
    }

    /**
     * Description: Opens the modal with desired options, and handles the closing result
     * @param: modalChoice: string - defines which modal we want to open
     * @returns: None
     */
    openModal(modalChoice: string, event:Event): void {
        let modalType = this.termsOfUseModalConfig;
        // Terms of use translate
        this.translate.get('supplier.body.footer.terms').subscribe({
            next: (res: string) => {
                let data = JSON.parse(JSON.stringify(res));
                modalType.data.buttonText = data.buttonText
                modalType.data.content = data.content 
                modalType.data.title = data.title  
            }
        });
        const modalReference = this.ngbModalService.open(ModalComponent, modalType.options);
        event.preventDefault();
        // If specified, adds the callback function for when the user closes the modal via the button
        if (modalType.exitViaButtonCallback) {
            modalReference.result.then(modalType.exitViaButtonCallback);
        }

        // Pass the modal type and data to the modal
        modalReference.componentInstance.modalType = modalType.type;
        modalReference.componentInstance.modalData = modalType.data;
        modalReference.componentInstance.privacyFlag = true;
        let tsmRoot  =  <HTMLElement>document.getElementsByTagName("tsm-root")[0];
        if (tsmRoot) {
            tsmRoot.setAttribute("aria-hidden","false");
        }
    }

    openModalByBuyer(modalChoice: string, event:Event): void {
        const modalType =  this.termsOfUseModalConfig
        const modalReference = this.ngbModalService.open(
            ModalComponent,
            modalType.options
        );
        event.preventDefault();
        // If specified, adds the callback function for when the user closes the modal via the button
        if (modalType.exitViaButtonCallback) {
            modalReference.result.then(modalType.exitViaButtonCallback);
        }

        // Pass the modal type and data to the modal
        modalReference.componentInstance.modalType = modalType.type;
        modalReference.componentInstance.modalData = modalType.data;
        let tsmRoot  =  <HTMLElement>document.getElementsByTagName("tsm-root")[0];
        if (tsmRoot) {
            tsmRoot.setAttribute("aria-hidden","false");
        }
    }

    popupPrivacyStatement(event:Event){
        const currentCountry = localStorage.getItem("currentCountryLocation");
        const country = !!currentCountry ? currentCountry: "";
        event.preventDefault();
        this.eventService.privacyStatementEmit.emit({ country: country, isFooterPopup: true});
    }

    handleEnterKey(event: KeyboardEvent): void {
        if (event.key === 'Enter') {
            event.preventDefault();
            this.popupPrivacyStatement(event);
        }
    }

    /**
     * Description: Disable the hyperlink
     * @returns: None
     */
    onClick(): boolean {
        return false;
    }

    headerRole(): string {
        return this.authService.getRole();
    }
    
    jumpLink(){
        window.open('https://www.accenture.com/us-en/form-do-not-sell-my-personal-information',"_blank")
    }

    ngAfterContentChecked() {
        if (localStorage.getItem("multiLanguage")) {
          const multilanguageJson = JSON.parse(localStorage.getItem("multiLanguage"));
          if (multilanguageJson && multilanguageJson.body.footer) {
            this.supplierFooter = multilanguageJson.body.footer
          }
        }
    }
}
