import { EsgReportService } from './../../services/esgReport/esg-report.service';
import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { NavigationEnd, Router, RouterEvent, ActivatedRoute } from "@angular/router";
import { ReportService } from "src/app/services/authority/report.service";
import { SupplierRelationUpdateSuccessEventType, UploaderService, SubSupplierRelationshipService, SubSupplierEvents } from "@lsl16/sustainability-shared-components";
import { merge, Observable, Subject, Subscription } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";
import { EsgQuestionnaireService } from "@lsl16/sushub-frontend";
import { AlertPropsType } from "@lsl16/sustainability-shared-components";
import { SubheaderService } from "../../services/subheader/subheader.service";
import { SubheaderPropsType } from "../../models/SubheaderProps";
import { environment } from "../../../environments/environment";
import { AuthService } from "src/app/services/auth/auth.service";
import { NavigateEventType, RoleChangeEventType } from "./types";
import { Role } from "src/app/models/LoginUser";
import { AuthenticationResult, EventMessage, EventType } from "@azure/msal-browser";
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from "@azure/msal-angular";
import { ModalConfig, ModalType } from "../../services/modal/types";
import { privacyStatementModalData } from "../../services/modal/privacyStatementModalData";
import { ModalService } from "src/app/services/modal/modal.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NotificationService } from "src/app/services/notification/notification.service";
import { SupplierRelationshipService } from "@lsl16/sustainability-shared-components";
import { PopUpPrivacyComponent } from "../../components/pop-up-privacy/pop-up-privacy.component";
import { countryServedList } from "../../../utilities/const/country-served";
import { CountryServedService } from "../../services/countryServedData/country-served.service";
import { countryList } from "src/utilities/const/country-list";
import { SupplierInviteService } from 'src/app/services/inviteSupplier/supplier-invite.service';
import { InviteSupplierEvents } from 'src/app/models/InviteSupplier';
import { MetadataService } from "../../services/metadata/metadata.service";
import { ComponentConfig } from "@lsl16/sustainability-shared-components";
import { EventService } from "src/app/services/EventService.service";
import { HttpService } from "@lsl16/sustainability-shared-components";
import { RiskassessmentService } from 'src/app/services/riskassessment/riskassessment.service';

enum MessageId {
    validationCompletedMessage = "validationCompletedMessage",
    supplierRelationNoParentConfirmedMessage = "supplierRelationNoParentConfirmedMessage",
    relationshipRequestWithdrawnSuccessMessage = "relationshipRequestWithdrawnSuccessMessage",
    parentRelationshipRequestCancelledSuccessMessage = "parentRelationshipRequestCancelledSuccessMessage",
    subsidiaryRelationshipRequestCancelledSuccessMessage = "subsidiaryRelationshipRequestCancelledSuccessMessage",
    subSupplierRelationshipRequestCancelledSuccessMessage = "subSupplierRelationshipRequestCancelledSuccessMessage",
    reworkRequestedFromSupplier = "reworkRequestedFromSupplier",
    errorAlertText = "errorAlertText",
    reviewSummarySaveCommentSuccessMessage = "reviewSummarySaveCommentSuccessMessage",
    confirmInvitationSuccessMsg = "confirmInvitationSuccessMsg",
    rejectInvitationSuccessMsg = "rejectInvitationSuccessMsg",
    newParentInvitedMessage = "newParentInvitedMessage",
    newSubSupplierInvitedMessage = "newSubSupplierInvitedMessage",
    assignSubSupplierSuccessMessage = "assignSubSupplierSuccessMessage"
}

@Component({
    selector: "app-layout",
    templateUrl: "./layout.component.html",
    styleUrls: ["./layout.component.sass"]
})

export class LayoutComponent implements OnInit, OnDestroy {
    private layoutTranslation: {
        subsidiarySaAccessUpdateSuccessMessage: string;
        concurrentSustainabilityAssessments: string;
    };
    userId: string;
    pageUrl: string;
    role: string;
    routeListener: Observable<RouterEvent>;
    roleChangeListener: Observable<{ role: string }>;
    combinedSubscription: Subscription;

    subheaderProps: SubheaderPropsType;

    notificationProps: AlertPropsType;
    showNotification = false;
    timer: any;
    afterStart: boolean;
    beforeEnd: boolean;
    popPrivacyStatus = true;
    esgEventEmitSubscription: Subscription;

    questionnaireViewOnlyProps: AlertPropsType;
    questionnaireViewOnlyFlag: boolean;

    questionnaireConcurrentProps: AlertPropsType;
    questionnaireConcurrentFlag: boolean;

    successAlertProps: AlertPropsType;
    shouldShowSuccessAlert: boolean;

    ownToken: boolean;
    private readonly _destroying$ = new Subject<void>();
    privacyStatementModalConfig: { data: ModalType } & ModalConfig = {
        data: privacyStatementModalData,
        type: "privacy-statement-strict",
        options: {}
    };
    countryServedConfigs: Array<ComponentConfig> = [];
    onboardingConfigs: Array<ComponentConfig> = [];
    riskAssessmentConfigs: Array<ComponentConfig> = [];
    noParentSubmissionFlag: boolean;
    noParentSubmissionProps: AlertPropsType;
    noParentEventEmitSubscription: Subscription;
    saveSuccess: boolean;
    saveError: boolean;
    saveSuccessAlertProps: AlertPropsType = {
        type: "alert-success",
        message: "Country Served has been updated successfully.",
        icon: "assets/icons/tick.svg",
        iconFill: "#155724",
        iconClose: "assets/images/icon-cross-green.svg",
        onDismiss: () => {
            this.saveSuccess = false;
        }
    };

    saveErrorAlertProps: AlertPropsType = {
        type: "alert-requestVerifiedFaild",
        message: "There is a technical issue, please try again or contact us.",
        icon: "assets/images/icon-error.svg",
        iconFill: "#cc1e32",
        iconClose: "assets/images/icon-cross-blue.svg",
        onDismiss: () => {
            this.saveError = false;
        }
    };

    viewDetailsAlertProps: AlertPropsType = {
        type: "alert-requestVerifiedFaild",
        message: "No details are available for the selected RA ID.",
        icon: "assets/images/icon-error.svg",
        iconFill: "#cc1e32",
        iconClose: "assets/images/icon-cross-blue.svg",
        onDismiss: () => {
            this.riskAssessmentService.verifyAlertVisiable = false;
        }
        
    };
    bibdaScoreAlertProps: AlertPropsType = {
        type: "alert-success",
        message: "BI/BDA Risk Assessment saved successfully.",
        icon: "assets/icons/tick.svg",
        iconFill: "#155724",
        iconClose: "assets/images/icon-cross-green.svg",
        onDismiss: () => {
            this.bibdaScoreVisible = false;
        }
        
    };
    bibdaScoreVisible = false;

    /**
     * Constructor
     */
    constructor(
        private authService: AuthService,
        private modalService: ModalService,
        private router: Router,
        private activateInfo:ActivatedRoute,
        private subheaderService: SubheaderService,
        private esgQuestionnaireService: EsgQuestionnaireService,
        private uploaderService: UploaderService,
        @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
        private msalService: MsalService,
        private msalBroadcastService: MsalBroadcastService,
        private ngbModalService: NgbModal,
        private notificationService: NotificationService,
        private supplierRelationshipService: SupplierRelationshipService,
        private countryServedService: CountryServedService,
        private esgReportService: EsgReportService,
        private reportService: ReportService,
        private supplierInviteService: SupplierInviteService,
        private subSupplierRelationshipService: SubSupplierRelationshipService,
        private metadataService: MetadataService,
        private eventService: EventService,
        private HttpService: HttpService,
        public riskAssessmentService: RiskassessmentService
    ) {
        this.msalBroadcastService.msalSubject$
            .pipe(
                filter(
                    (msg: EventMessage) =>
                        msg.eventType === EventType.LOGIN_SUCCESS ||
                        msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
                        msg.eventType === EventType.HANDLE_REDIRECT_START
                ),
                takeUntil(this._destroying$)
            )
            .subscribe(async (result: EventMessage) => {
                if (result.eventType === EventType.HANDLE_REDIRECT_START) {
                    this.popPrivacyStatus = false;
                    if (this.authService.getRole() !== "supplier") {
                        this.eventService.privacyStatementEmit.emit({ country: "" })
                    }
                } else {
                    const payload = <AuthenticationResult>result.payload;

                    if (payload.idToken) {
                        this.authService.saveToken(payload.idToken);
                        await this.authService.handleButtonPermission();
                    }

                    this.ownToken = !!payload.idToken;
                }
            });
        if (this.popPrivacyStatus === true) {
            if (this.authService.getRole() !== "supplier") {
                this.eventService.privacyStatementEmit.emit({ country: "" })
            }
        }
        this.routeListener = router.events.pipe(
            filter((event) => event instanceof NavigationEnd)
        ) as Observable<RouterEvent>;

        this.roleChangeListener = this.authService.roleChangeEventEmitter;

        this.combinedSubscription = merge(this.roleChangeListener, this.routeListener).subscribe(
            async (event: NavigateEventType): Promise<void> => {
                let routeChangeEvent: NavigationEnd;
                let roleChangeEvent: RoleChangeEventType;

                let role: Role = this.authService.getRole();

                function isRoleChangeEvent(toBeDetermined: NavigateEventType): toBeDetermined is RoleChangeEventType {
                    if ((toBeDetermined as RoleChangeEventType).role) {
                        return true;
                    }
                    return false;
                }
                this.pageUrl = window.location.hash.replace("#", "");
                if (isRoleChangeEvent(event)) {
                    roleChangeEvent = event;
                    role = <Role>roleChangeEvent.role;
                } else {
                    routeChangeEvent = event;
                    this.pageUrl = routeChangeEvent.urlAfterRedirects;
                }

                this.subheaderProps = await this.subheaderService.getSubheaderProps(this.pageUrl);

                // Temp solution, both supplier view and buyer view use the route path "/" for landing page
                // for now supplier view landing page is the supplier relationship page which doesn't have a subheader
                // In the future the supplier view landing page might have a subheader
                if (this.pageUrl === "/" && role === "supplier") {
                    this.subheaderProps = null;
                }

                this.removeButtonsForReviewSummary();

                // set current platform based on route path
                if (this.pageUrl.startsWith("/esg-questionnaire")) {
                    localStorage.setItem("platform", "sph");
                } else {
                    localStorage.setItem("platform", "tsm");
                    this.questionnaireViewOnlyFlag = false;
                    this.noParentSubmissionFlag = false;
                    this.questionnaireConcurrentFlag = false;
                    this.shouldShowSuccessAlert = false;
                    this.saveError = false;
                }
            }
        );
    }

    removeButtonsForReviewSummary(): void {
        if (this.pageUrl.startsWith("/esg-questionnaire/survey-review-summary")) {
            if (this.subheaderProps.allQuestionsButtonProps) {
                this.subheaderProps.allQuestionsButtonProps = null;
            }
            if (this.subheaderProps.answeredButtonProps) {
                this.subheaderProps.answeredButtonProps = null;
            }
            if (this.subheaderProps.unansweredButtonProps) {
                this.subheaderProps.unansweredButtonProps = null;
            }
        }
    }

    async ngOnInit(): Promise<void> {
        // compute notification show or not
        await this.notificationAlert();
        this.ownToken = !!localStorage.getItem("sustain_access_token");
        await this.handleSubheaderProps();

        this.esgQuestionnaireServiceSubscription();
        this.uploaderService.url = `${environment.susHubESGServiceURL}`;

        // countryServedList['Data'] = await this.countryServedService.countryServedFilter();
        countryList['Data'] = await this.countryServedService.countryList();
        countryList['Data'].servedCountryList = this.sortSelectResource(countryList['Data'].servedCountryList);
        const marketUnitList = this.getAllMarketUnit(countryList['Data'].servedCountryList);
        localStorage.setItem("marketUnitList", JSON.stringify(marketUnitList));
        localStorage.setItem("countryList", JSON.stringify(countryList['Data']));
        this.countryServedConfigs = await this.metadataService.getSelectCountryServedConfig();
        localStorage.setItem("countryServedConfigs", JSON.stringify(this.countryServedConfigs));

        //To set the Onboarding popup form in to local storage
        this.onboardingConfigs = await this.metadataService.getOnboardingConfig();
        localStorage.setItem("onboardingConfigs", JSON.stringify(this.onboardingConfigs));
        
        //To set the RA popup form in to local storage
        this.riskAssessmentConfigs = await this.metadataService.getRiskAssessmentConfig();
        localStorage.setItem("riskAssessmentConfigs", JSON.stringify(this.riskAssessmentConfigs));

        // getting category details from PG table
        const categoryDataURL = `${environment.tsmBackendServiceURL}/riskAssessmentData/getRiskAssessmentData`;
        const categoryList = this.getAllCategoryList(await this.HttpService.GetPromise(categoryDataURL));
        const subCategoryList = this.getAllSubCategoryList(await this.HttpService.GetPromise(categoryDataURL));
        localStorage.setItem("categoryList", JSON.stringify(categoryList));
        localStorage.setItem("subCategoryList", JSON.stringify(subCategoryList));

        this.subscribeToEvents();

        this.supplierRelationshipService.nationalMessageTips.subscribe(next => {
            this.saveError = next ? false : false;
            this.saveSuccess = next ? true : false;
        });
        this.supplierRelationshipService.nationalMessageErrTips.subscribe(next => {
            this.saveSuccess = next ? false : false;
            this.saveError = next ? true : false;
        });

    }

    getAllMarketUnit(servedCountryList) {
        let marketUnitList = []
        servedCountryList.forEach((item) => {
            if (item.marketUnit) {
                marketUnitList.push({
                    Text: item.marketUnit,
                    Code: item.marketUnit
                })
            }
        })
        marketUnitList = this.sortSelectResource(marketUnitList)
        return this.removeDuplicateObj(marketUnitList)
    }

    getAllCategoryList(categoryList) {
        let categotySortList = []
        categoryList.categoryRA.forEach((category) => {
            if (category.categoryVisible === true) {
                categotySortList.push({
                    Text: category.categoryName,
                    Code: category.categoryCode
                })
            }
        })
        categotySortList = this.sortSelectResource(categotySortList);
        return this.removeDuplicateObj(categotySortList)
    }

    getAllSubCategoryList(subCategoryList) {
        let subCategotySortList = []
        subCategoryList.subcategoryRA.forEach((subCategory) => {
            if (subCategory.subCategoryVisible === true) {
                subCategotySortList.push({
                    Text: subCategory.subCategoryName,
                    Code: subCategory.subCategoryCode,
                    categoryCode: subCategory.categoryCode,
                    gdprEnable: subCategory.gdprEnable,
                    brEnable: subCategory.brEnable,
                    assetSafetyEnable: subCategory.assetSafetyEnable
                })
            }
        })
        subCategotySortList = this.sortSelectResource(subCategotySortList);
        return subCategotySortList;
    }

    removeDuplicateObj(arr) {
        let obj = {};
        arr = arr.reduce((newArr, next) => {
            obj[next.Text] ? "" : (obj[next.Text] = true && newArr.push(next));
            return newArr;
        }, []);
        return arr;
    }

    sortSelectResource = (resource: any): any => {
        return resource?.sort((obj1: any, obj2: any) => {
            const val1 = obj1.Text;
            const val2 = obj2.Text;
            if (val1 > val2) {
                return 1;
            } else if (val1 < val2) {
                return -1;
            } else {
                return 0;
            }
        });
    }

    private subscribeToEvents(): void {
        this.supplierRelationshipService.getSupplierRelationUpdateSuccessEvent()
            .subscribe(next => this.handleSupplierRelationUpdateSuccessEvent(next));

        this.supplierRelationshipService.getSubsidiarySaAccessUpdateSuccessEvent()
            .subscribe(next => this.handleSubsidiarySaAccessRightsUpdateSuccessEvent(next));

        this.esgQuestionnaireService.getValidationCompletedEvent().subscribe(() => {
            this.handleValidationCompletedEvent()
        })

        this.supplierRelationshipService.eventEmit.subscribe((event) => {
            this.refreshAlerts(event);
        })

        this.esgReportService.getErrorEvent().subscribe(message => {
            this.showErrorAlert(message);
        });

        this.supplierInviteService.eventEmit.subscribe((event) => {
            this.handleSupplierInviteEvent(event);
        })
        this.subSupplierRelationshipService.eventEmit.subscribe((event) => {
            this.handleSubSupplierServiceEvents(event);
        })

        this.riskAssessmentService.bibdaEventEmit.subscribe((event) => {
            this.bibdaScoreVisible = event ? true : false
        })
    }


    async notificationAlert(): Promise<void> {
        this.afterStart = false;
        this.beforeEnd = false;
        const notificationList = await this.notificationService.getNotificationList("downtime");
        let notification = null;
        if (notificationList && notificationList.length > 0) {
            notification = notificationList[0];
        } else {
            return;
        }
        const showTimeStart = new Date(notification.showTimeStart).getTime();
        const showTimeEnd = new Date(notification.showTimeEnd).getTime();
        const now = new Date().getTime();
        this.afterStart = now >= showTimeStart;
        this.beforeEnd = showTimeEnd >= now;
        this.timer = setInterval(() => {
            const nowTime = new Date().getTime();
            this.afterStart = nowTime >= showTimeStart;
            this.beforeEnd = showTimeEnd >= nowTime;
        }, 60000);
        this.showNotification = true;
        let message = "";
        if (notification.text) {
            message = message.concat(notification.text);
        }
        if (notification.email) {
            const emailHtml = `&nbsp;<a class='emailTextColor' href=mailto:${notification.email}>${notification.email}</a>`;
            message = message.concat(emailHtml);
        }
        if (notification.link) {
            const linkHtml = `&nbsp;<a class='emailTextColor' target='_blank' href='${notification.link}'>here</a>`;
            message = message.concat(linkHtml);
        }
        this.notificationProps = {
            type: "downtime-alert",
            message,
            hideCloseIcon: true
        };
    }

    async handleSubheaderProps(): Promise<void> {
        this.pageUrl = window.location.hash.replace("#", "");
        this.role = this.authService.getRole();
        if (this.pageUrl === "/" && this.role === "supplier") {
            this.subheaderProps = null;
        } else {
            this.subheaderProps = await this.subheaderService.getSubheaderProps(this.pageUrl);
            this.removeButtonsForReviewSummary();
        }
        // set current platform based on route path
        if (this.pageUrl.startsWith("/esg-questionnaire")) {
            localStorage.setItem("platform", "sph");
        } else {
            localStorage.setItem("platform", "tsm");
            this.questionnaireViewOnlyFlag = false;
            this.noParentSubmissionFlag = false;
            this.questionnaireConcurrentFlag = false;
            this.shouldShowSuccessAlert = false;
            this.saveError = false;
        }
    }

    // Unsubscribes from routeListener to avoid NavigationEnd event being emitted multiple times
    ngOnDestroy(): void {
        this.combinedSubscription.unsubscribe();
        this._destroying$.next(undefined);
        this._destroying$.complete();
        if (this.timer) {
            clearInterval(this.timer);
        }
    }

    getUserId = (): void => {
        this.userId = this.authService.getLoginUser().userId;
    }

    /**
     * Description: Subscribes to #eventEmit of esgQuestionnaireService and sets #subheaderProps accordingly.
     * @returns: None
     */
    esgQuestionnaireServiceSubscription = (): void => {
        this.esgEventEmitSubscription = this.esgQuestionnaireService.eventEmit.subscribe((event) => {
            let isString = false;
            if (typeof event === "string") {
                isString = true;
            }
            if (!isString) {
                return;
            }
            let emitArry = event.split("#");
            emitArry[0] === "saveSuccess" &&
                this.router.url.startsWith("/esg-questionnaire") &&
                (this.subheaderProps = {
                    isWelcomeMessage: false,
                    headingPrefixColor: "purple",
                    heading: "Sustainability Assessment",
                    subheaderButtonProps: {
                        iconPosition: "left",
                        label: "Saved",
                        color: "purple",
                        backgroundColor: "transparent",
                        borderColor: "transparent",
                        icon: "assets/icons/saved.svg",
                        clickIcon: "assets/icons/saved.svg"
                    }
                });
            if (event === "viewOnly" && this.activateInfo.snapshot.queryParams.esgStatus !== 'Submitted and Scored for RFPs') {
                this.questionnaireViewOnlyFlag = true;
                this.questionnaireViewOnlyProps = {
                    type: "alert-ViewOnly",
                    message:
                        "This questionnaire has been submitted: you cannot edit it. If you need to change a response, please email the Help & Support Team at procurement.support@accenture.com.",
                    icon: "assets/icons/icon-info-gray.svg",
                    iconFill: "#376803",
                    iconClose: "assets/images/icon-cross-green.svg",
                    onDismiss: this.onAlertDismiss
                };
            }
            if (event === "concurrentSustainabilityAssessments") {
                this.questionnaireConcurrentFlag = true;
                this.questionnaireConcurrentProps = {
                    type: "alert-concurrentAssessments",
                    message: this.layoutTranslation.concurrentSustainabilityAssessments,
                    icon: "assets/icons/icon-info-blue.svg",
                    iconFill: "#3F72D2",
                    iconClose: "assets/images/icon-cross-blue2.svg",
                    iconCloseFill: "#3F72D2",
                    onDismiss: this.onConcurrentSaAlertDismiss
                };
            }

            if (event === "reworkRequestedFromSupplier") {
                const message = MessageId.reworkRequestedFromSupplier;
                this.showSuccessAlert(message);
            } else if (event === "reworkRequestedFromSupplierFailed" || event === "saveCommentError") {
                const message = MessageId.errorAlertText;
                this.showErrorAlert(message);
            } else if (event === "saveCommentSuccess") {
                const message = MessageId.reviewSummarySaveCommentSuccessMessage;
                this.showSuccessAlert(message);
            }
        });
    }

    private showSuccessAlert(message: string): void {
        this.successAlertProps = {
            type: "alert-requestVerifiedSuccess",
            message: message,
            icon: "assets/images/icon-tick.svg",
            iconClose: "assets/images/icon-cross-green.svg",
            onDismiss: () => { this.shouldShowSuccessAlert = false; }
        };
        this.shouldShowSuccessAlert = true;
    }

    private showErrorAlert(message: string): void {
        this.saveErrorAlertProps.message = message;
        this.saveError = true;
    }

    private handleSubsidiarySaAccessRightsUpdateSuccessEvent(username: string) {
        const message = this.layoutTranslation.subsidiarySaAccessUpdateSuccessMessage.replace("${username}", username);
        this.showSuccessAlert(message);
    }

    private handleValidationCompletedEvent() {
        const message = MessageId.validationCompletedMessage;
        this.showSuccessAlert(message);
    }

    handleSupplierRelationUpdateSuccessEvent(eventType: SupplierRelationUpdateSuccessEventType) {
        if (eventType === "NoParentConfirmed") {
            this.noParentSubmissionFlag = true;
            this.noParentSubmissionProps = {
                type: "alert-requestVerifiedSuccess",
                message: MessageId.supplierRelationNoParentConfirmedMessage,
                icon: "assets/images/icon-tick.svg",
                iconFill: "#376803",
                iconClose: "assets/images/icon-cross-green.svg",
                onDismiss: () => {
                    this.noParentSubmissionFlag = false;
                }
            };
        }
        if (eventType === "RelationshipRequestWithdrawn") {
            this.showSuccessAlert(MessageId.relationshipRequestWithdrawnSuccessMessage);
        }
        if (eventType === "ParentRelationshipRequestCancelled") {
            this.showSuccessAlert(MessageId.parentRelationshipRequestCancelledSuccessMessage);
        }
        if (eventType === "SubsidiaryRelationshipRequestCancelled") {
            this.showSuccessAlert(MessageId.subsidiaryRelationshipRequestCancelledSuccessMessage);
        }
        if (eventType === "NewParentInvited") {
            this.showSuccessAlert(MessageId.newParentInvitedMessage);
        }

    }

    handleSubSupplierServiceEvents(eventType: SubSupplierEvents) {
        if (eventType === 'ConfirmedNewSupplier') {
            this.showSuccessAlert(MessageId.assignSubSupplierSuccessMessage);
        }
        if (eventType === "NewSupplierInvited") {
            this.showSuccessAlert(MessageId.newSubSupplierInvitedMessage);
        }
        if (eventType === "RelationshipCancelled") {
            this.showSuccessAlert(MessageId.subSupplierRelationshipRequestCancelledSuccessMessage);
        }
    }

    onAlertDismiss = (): void => {
        this.questionnaireViewOnlyFlag = false;
    }

    onConcurrentSaAlertDismiss = (): void => {
        this.questionnaireConcurrentFlag = false;
    }

    refreshAlerts = (event: string): void => {
        if (event === 'refreshAlerts') {
            this.shouldShowSuccessAlert = false;
            this.noParentSubmissionFlag = false;
            this.saveError = false;
        }
    }

    handleSupplierInviteEvent(eventType: InviteSupplierEvents) {
        if (eventType === "confirmSuccess") {
            this.showSuccessAlert(MessageId.confirmInvitationSuccessMsg);
        }
        if (eventType === "rejectSuccess") {
            this.showSuccessAlert(MessageId.rejectInvitationSuccessMsg);
        }
        if (eventType === "error") {
            this.showErrorAlert(MessageId.errorAlertText);
        }
    }

    ngAfterContentChecked() {
        if (localStorage.getItem('multiLanguage')) {
            const multilanguageJson = JSON.parse(localStorage.getItem('multiLanguage'));
            if (multilanguageJson.body != undefined) {
                this.layoutTranslation = multilanguageJson.body.layout;
                if (this.questionnaireConcurrentFlag) {
                    this.questionnaireConcurrentProps.message = this.layoutTranslation.concurrentSustainabilityAssessments;
                }
            }
        }
    }
}
